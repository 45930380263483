import React, { CSSProperties, useEffect, useState } from 'react';
import {
  Button,
  Card,
  CardContent,
  Collapse,
  Grid,
  LinearProgress,
  Paper,
  Tooltip,
  Typography,
} from '@material-ui/core';
import {
  Add,
  CheckCircleTwoTone,
  CloudUploadTwoTone,
  DoubleArrow,
  HourglassFullTwoTone,
  Info,
  MouseTwoTone,
  Remove,
} from '@material-ui/icons';
import {
  ExpandedJobStatusType,
  fetchFilesWithStatus,
  FileWithStatus,
  getButtonBackgroundColor,
  getButtonIsDisabled,
  getCurrentParserStep,
  getCurrentPreprocessorStep,
  getPieDataByType,
  PARSER_JOB_EXPIRY_TIME_MS,
  pollForStatus,
  PREPROCESSOR_JOB_EXPIRY_TIME_MS,
  publish,
  PublishType,
} from './DCCUtils';
import CircularLoader from '../loader/CircularLoader';
import { formatFileSize, getFileBlob } from '../../scripts/utils';
import PDFIcon from '../icons/PDF-icon';
import CancelIcon from '@material-ui/icons/Cancel';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { descendingComparator } from '../document-index/DocumentIndexUtils';
import { useTimer } from 'react-timer-hook';
import dayjs from 'dayjs';
import {
  CropConfig,
  FileNode,
  IConformingCenterProjectSummary,
  JobType,
} from '../../api-client/autogenerated';
import FileUploadDialog, { FileType } from '../dialogs/FileUploadDialog';
import DrawingsDialog from '../dialogs/DrawingsDialog';
import {
  beginDrawingsParser,
  beginDrawingsPreprocessor,
  editFileById,
  importProjectDrawingsFileFromDesign,
  uploadProjectDrawingsFile,
} from '../../models/api/filesystem';
import { MULTI_PART_FILE_SIZE } from '../../scripts/constants';
import { reloadProject } from '../../features/project/actions';
import { useDispatch, useSelector } from 'react-redux';
import { getProjectState } from '../../features/project/selectors';
import { allowNavigation, blockNavigation } from '../../features/navigation/actions';
import { useHistory } from 'react-router';
import { useInterval } from '../custom-components/useInterval';
import { reloadDocuments } from '../../features/documents/actions';
import IconButton from '@material-ui/core/IconButton';
import { ImportedFile } from '../dialogs/ImportFromDocumentDialog';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      padding: theme.spacing(3),
      minHeight: '100vh',
      overflowX: 'hidden',
    },
    paper: {
      color: theme.palette.text.secondary,
      height: '100%',
      boxShadow: '5px 5px 15px rgb(0, 0, 0, .15)',
      width: '100%',
    },
    cardRoot: {
      left: '235px',
      top: '1511px',
    },
    titleStyle: {
      background: 'linear-gradient(225deg, #00308C 0%, #002366 100%)',
      borderRadius: '4px 4px 0px 0px',
      display: 'flex',
      justifyContent: 'space-between',
    },
    text: {
      fontSize: 12,
      color: '#727272',
      lineHeight: '14.06px',
      marginBottom: 25,
    },
    formControl: {
      display: 'flex',
      width: '40%',
      marginLeft: 20,
    },
    selectAnchor: {
      position: 'relative',
      top: 15,
      left: 20,
    },
    drawerSelect: {
      height: '32px',
      /* Gray / Gray 50 */
      background: '#F9F9F9',

      /* Gray / Gray 400 Brand dark */
      border: '1px solid #949494',
      'border-radius': '5px',
    },
    menuPaper: {
      maxHeight: 400,
      maxWidth: 300,
      overflowWrap: 'break-word',
      whiteSpace: 'unset',
    },
    buttonAndForm: {
      display: 'flex',
      width: '85%',
      alignItems: 'center',
      justifyContent: 'center',
      marginBottom: 32,
    },
    cardBody: {
      display: 'flex',
      width: '100%',
      alignItems: 'center',
      flexDirection: 'column',
    },
    statusHidden: {
      display: 'none',
    },
    statusVisible: {
      display: 'inline',
    },
    fileContainer: {
      display: 'flex',
      flexWrap: 'wrap',
      overflowY: 'auto',
      columnGap: 16,
      rowGap: '8px', //cannot use number for this property for some reason
      justifyContent: 'space-evenly',
      width: '85%',
      height: 304,
      paddingTop: 8,
      marginBottom: 32,
      borderWidth: 2,
      borderStyle: 'solid',
      borderColor: 'black',
    },
    progressBar: {
      backgroundColor: '#128750',
    },
    iconSpin: {
      position: 'absolute',
      top: 0,
      right: 0,
      animation: 'spin, spinback',
      animationDelay: '.5s, .5s',
      animationDuration: '1s',
      animationIterationCount: 'infinite',
    },
  }),
);

type Props = {
  pageIsLoading?: boolean;
  setPageIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  summary: IConformingCenterProjectSummary | undefined;
  fetchSummary: () => Promise<IConformingCenterProjectSummary>;
  isDocumentLogPage?: boolean;
};

export default function PublishingCenterDrawings(props: Props) {
  const { setPageIsLoading, pageIsLoading, summary, fetchSummary, isDocumentLogPage } = props;
  const classes = useStyles();

  const history = useHistory();
  const dispatch = useDispatch();
  const currentProject = useSelector(getProjectState);

  const [open, setOpen] = useState(true);

  const [drawingsFiles, setDrawingsFiles] = useState<FileWithStatus[]>([]);

  const [drawingsDialogOpen, setDrawingsDialogOpen] = useState(false);
  const [inputDrawingsFile, setInputDrawingsFile] = useState<FileType>();
  const [drawingsFileForParser, setDrawingsFileForParser] = useState<File | Blob>();
  const [regionSelectorOpen, setRegionSelectorOpen] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [isPublishing, setIsPublishing] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);
  const [downloadProgress, setDownloadProgress] = useState(0);
  const [uploadProgress, setUploadProgress] = useState(0);

  const [drawingsPieData, setDrawingsPieData] = useState<number[]>([0, 0, 0]);

  const currentDrawingsFile: FileWithStatus | undefined = drawingsFiles.sort((a, b) =>
    descendingComparator(a.file, b.file, 'createdOn'),
  )[0];

  const drawingsTimerExpiryTime =
    currentDrawingsFile?.progress?.stage === 'optimizing'
      ? PREPROCESSOR_JOB_EXPIRY_TIME_MS
      : PARSER_JOB_EXPIRY_TIME_MS;

  const {
    minutes: drawingsTimerMinutes,
    seconds: drawingsTimerSeconds,
    totalSeconds: drawingsTimerTotalSeconds,
    restart: drawingsTimerRestart,
  } = useTimer({
    autoStart: false,
    expiryTimestamp: currentDrawingsFile?.createdOn
      ? dayjs(currentDrawingsFile?.createdOn).add(drawingsTimerExpiryTime, 'ms').toDate()
      : dayjs().add(drawingsTimerExpiryTime, 'ms').toDate(),
  });

  useEffect(() => {
    if (currentDrawingsFile?.createdOn)
      drawingsTimerRestart(
        dayjs(currentDrawingsFile.createdOn).add(drawingsTimerExpiryTime, 'ms').toDate(),
      );
  }, [currentDrawingsFile]);

  useInterval(() => {
    pollForStatus(drawingsFiles, setDrawingsFiles, JobType.DrawingsParser, fetchSummary);
  }, 10000);

  useEffect(() => {
    if (!summary) return;
    setDrawingsPieData(getPieDataByType(summary, PublishType.Drawings));
  }, [summary]);

  useEffect(() => {
    if (currentProject && summary) {
      fetchFilesWithStatus(currentProject, summary, PublishType.Drawings).then((files) => {
        setDrawingsFiles(files);
        handleExternalJob(files);
      });
    }
  }, [currentProject, summary]);

  const handleExternalJob = async (files: FileWithStatus[]) => {
    const parseDrawings = sessionStorage.getItem('parseDrawings');
    if (parseDrawings) {
      setIsLoading(true);
      const { name, fileId } = JSON.parse(parseDrawings) as { name: string; fileId: string };
      sessionStorage.removeItem('parseDrawings');
      const currentFile = files.sort((a, b) =>
        descendingComparator(a.file, b.file, 'createdOn'),
      )[0];
      if (currentFile) {
        await editFileById(currentFile.file.id, {
          isHiddenInPublishingCenter: true,
        });
      }
      await beginDrawingsPreprocessor(currentProject!.id, fileId);
      dispatch(reloadProject()).then(() => {
        setIsLoading(false);
      });
    }
  };

  const handleUploadProgress = (event: any) => {
    setUploadProgress(Math.round((100 * event.loaded) / event.total));
  };

  const handleDownloadProgress = (event: any) => {
    setDownloadProgress(Math.round(100 * event.loaded) / event.total);
  };

  const removeDrawingsFile = () => {
    setInputDrawingsFile(undefined);
    setDrawingsFileForParser(undefined);
  };

  const addDrawingsFile = async (newFile: FileType) => {
    setInputDrawingsFile(newFile);
  };

  const submitDrawingsFile = async () => {
    if ((inputDrawingsFile as FileNode).relativeKey) {
      const response = (
        await importProjectDrawingsFileFromDesign(currentProject!.id, {
          ownerId: (inputDrawingsFile as FileNode).ownerId,
          projectId: (inputDrawingsFile as FileNode).projectId,
          fullFileName: (inputDrawingsFile as FileNode).relativeKey!,
          fullKey: (inputDrawingsFile as FileNode).fullKey,
        })
      ).s3Response;
      if (response?.file) {
        await beginDrawingsPreprocessor(currentProject!.id, response.file.id);
      }
      setUploadProgress(100);
    } else if ((inputDrawingsFile as File).lastModified) {
      const response = (
        await uploadProjectDrawingsFile(
          currentProject!.id,
          {
            fullFileName: (inputDrawingsFile as File).name,
            useMultiPartUpload: (inputDrawingsFile as File).size > MULTI_PART_FILE_SIZE,
          },
          inputDrawingsFile as File,
          handleUploadProgress,
        )
      ).s3Response;
      if (response?.file) {
        await beginDrawingsPreprocessor(currentProject!.id, response.file.id);
      }
    } else {
      await beginDrawingsPreprocessor(currentProject!.id, (inputDrawingsFile as ImportedFile).id);
      setUploadProgress(100);
    }
    if (currentDrawingsFile?.file) {
      await editFileById(currentDrawingsFile.file.id, { isHiddenInPublishingCenter: true });
    }
    setDrawingsDialogOpen(false);
    dispatch(reloadProject());
  };

  const beginRegionSelection = async () => {
    if (!currentDrawingsFile?.output) return;
    setIsDownloading(true);
    const file = await getFileBlob(
      currentDrawingsFile.output.processedFileId,
      true,
      handleDownloadProgress,
    );
    if (file) {
      setDrawingsFileForParser(file);
      setRegionSelectorOpen(true);
    }
    setIsDownloading(false);
  };

  const handleParsedDrawingSubmit = async (
    titleBlockCropConfig: CropConfig,
    sheetNameCropConfig: CropConfig,
  ) => {
    console.log(titleBlockCropConfig, sheetNameCropConfig);
    if (currentDrawingsFile?.output) {
      try {
        dispatch(blockNavigation());

        await beginDrawingsParser(
          currentDrawingsFile.output.processedFileId,
          currentDrawingsFile.file.id,
          titleBlockCropConfig,
          sheetNameCropConfig,
        );
      } finally {
        dispatch(allowNavigation());
        setDrawingsDialogOpen(false);
        setInputDrawingsFile(undefined);
        setUploadProgress(0);
        setPageIsLoading(true);
        const summary = await fetchSummary();
        setDrawingsFiles(
          await fetchFilesWithStatus(currentProject!, summary, PublishType.Drawings),
        );
        setPageIsLoading(false);
      }
    } else {
      console.log('failed');
    }
  };

  const getDrawingsProgressContent = () => {
    let currentFile: FileWithStatus | null = currentDrawingsFile;
    // currentFile = {
    //   file: {
    //     id: '',
    //     lastUpdatedByUserId: '',
    //     category: FileCategoryType.Drawings,
    //     creatorUserId: '',
    //     isPublic: false,
    //     name: 'file.pdf',
    //   },
    //   status: ExpandedJobStatusType.Complete,
    //   progress: { stage: 'parsing' },
    //   percentComplete: 100,
    // };
    let totalSteps = 5;
    let getCurrentStep = getCurrentParserStep;
    if (currentFile?.progress?.stage === 'optimizing') {
      totalSteps = 4;
      getCurrentStep = getCurrentPreprocessorStep;
    } else if (currentFile) {
      totalSteps = 5;
      getCurrentStep = getCurrentParserStep;
    }

    if (!currentFile) return null;
    const jobFailed = [
      ExpandedJobStatusType.Canceled,
      ExpandedJobStatusType.JobCreationFailed,
    ].includes(currentFile.status);
    const isOptimizing = currentFile.progress?.stage === 'optimizing';
    const isParsing = currentFile.progress?.stage === 'parsing';
    const width = 117;
    const height = 143;
    const completeFill = jobFailed ? '#ED3F26' : '#128750';
    const labelStyle: CSSProperties = {
      position: 'absolute',
      bottom: 17,
      textAlign: 'center',
      lineHeight: '16px',
      fontWeight: 600,
    };
    const iconStyle: CSSProperties = {
      position: 'absolute',
      top: 0,
      right: 0,
      fontSize: '1.6rem',
    };
    const step1Color = currentFile.percentComplete !== 100 && !isParsing ? '#0947B9' : completeFill;
    const step2Color =
      isOptimizing && currentFile?.percentComplete === 100
        ? '#0947B9'
        : isParsing
        ? completeFill
        : undefined;
    const step3Color =
      isParsing &&
      ![
        ExpandedJobStatusType.Complete,
        ExpandedJobStatusType.Canceled,
        ExpandedJobStatusType.JobCreationFailed,
      ].includes(currentFile.status)
        ? '#0947B9'
        : [
            ExpandedJobStatusType.Complete,
            ExpandedJobStatusType.Canceled,
            ExpandedJobStatusType.JobCreationFailed,
          ].includes(currentFile.status)
        ? completeFill
        : undefined;
    return (
      <div
        style={{
          width: '85%',
          display: 'flex',
          flexDirection: 'column',
          marginBottom: 16,
        }}
      >
        <Typography style={{ fontSize: 18, fontWeight: 600, marginBottom: 16 }}>
          {currentFile.file.name}{' '}
          {currentFile.file.size ? `(${formatFileSize(currentFile.file.size)})` : ''}
        </Typography>

        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            paddingTop: 16,
          }}
        >
          <div
            style={{
              position: 'relative',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            {(currentFile.percentComplete === 100 || isParsing) && !jobFailed ? (
              <CheckCircleTwoTone style={{ ...iconStyle, color: '#128750' }} />
            ) : (
              <CloudUploadTwoTone
                style={{
                  ...iconStyle,
                  color: step1Color,
                }}
              />
            )}
            <span style={{ ...labelStyle, color: step1Color }}>Optimizing</span>
            <PDFIcon width={width} height={height} fill={step1Color} />
          </div>
          <DoubleArrow fontSize="large" />
          <div
            style={{
              position: 'relative',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            {isParsing && !jobFailed ? (
              <CheckCircleTwoTone style={{ ...iconStyle, color: '#128750' }} />
            ) : (
              <MouseTwoTone
                style={{
                  ...iconStyle,
                  top: 9,
                  right: 9,
                  color: step2Color,
                }}
              />
            )}
            <span style={{ position: 'absolute', top: -20, whiteSpace: 'nowrap' }}>
              User input required
            </span>
            <span
              style={{
                ...labelStyle,
                whiteSpace: 'pre',
                textAlign: 'center',
                color: step2Color,
              }}
            >
              Select{'\n'}Title Blocks
            </span>
            <PDFIcon
              width={width + 16}
              height={height + 16}
              fill={step2Color}
              style={{ border: '2px solid black', padding: 8 }}
            />
          </div>
          <DoubleArrow fontSize="large" />
          <div
            style={{
              position: 'relative',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            {currentFile.status === ExpandedJobStatusType.Complete ? (
              <CheckCircleTwoTone style={{ ...iconStyle, color: '#128750' }} />
            ) : (
              <HourglassFullTwoTone
                className={
                  isParsing && !jobFailed && currentFile.percentComplete !== 100
                    ? classes.iconSpin
                    : undefined
                }
                style={{ ...iconStyle, color: step3Color }}
              />
            )}
            <span style={{ ...labelStyle, color: step3Color }}>Parsing</span>
            <PDFIcon width={width} height={height} fill={step3Color} />
          </div>
          <DoubleArrow fontSize="large" />
          <div
            style={{
              position: 'relative',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            {jobFailed ? (
              <CancelIcon style={{ ...iconStyle, color: '#FF5D45' }} />
            ) : (
              <CheckCircleTwoTone
                style={{
                  ...iconStyle,
                  color:
                    currentFile.status === ExpandedJobStatusType.Complete ? '#2BB073' : undefined,
                }}
              />
            )}
            <span
              style={{
                ...labelStyle,
                color: [
                  ExpandedJobStatusType.Complete,
                  ExpandedJobStatusType.Canceled,
                  ExpandedJobStatusType.JobCreationFailed,
                ].includes(currentFile.status)
                  ? completeFill
                  : undefined,
              }}
            >
              {jobFailed ? 'Failed' : 'Success'}
            </span>
            <PDFIcon
              width={width}
              height={height}
              fill={
                [
                  ExpandedJobStatusType.Complete,
                  ExpandedJobStatusType.Canceled,
                  ExpandedJobStatusType.JobCreationFailed,
                ].includes(currentFile.status)
                  ? completeFill
                  : undefined
              }
            />
          </div>
        </div>
        {currentFile.percentComplete !== 100 &&
        ![
          ExpandedJobStatusType.Complete,
          ExpandedJobStatusType.Canceled,
          ExpandedJobStatusType.JobCreationFailed,
        ].includes(currentFile.status) ? (
          <>
            <div style={{ display: 'flex', alignItems: 'center', width: '100%', marginTop: 16 }}>
              <LinearProgress
                variant="determinate"
                value={
                  currentFile.percentComplete ??
                  (1 - drawingsTimerTotalSeconds / (drawingsTimerExpiryTime / 1000)) * 100
                }
                classes={{ bar: classes.progressBar }}
                style={{
                  width: '100%',
                  height: 32,
                  borderRadius: 8,
                  backgroundColor: '#a4d1bc',
                }}
              />
              <Typography
                style={{ fontSize: 16, fontWeight: 600, marginLeft: 8 }}
              >{`${drawingsTimerMinutes.toLocaleString('en-US', {
                minimumIntegerDigits: 2,
              })}:${drawingsTimerSeconds.toLocaleString('en-US', {
                minimumIntegerDigits: 2,
              })}`}</Typography>
            </div>
            <Typography
              align="center"
              style={{ width: '100%', fontSize: 16, fontWeight: 600, marginTop: 4 }}
            >
              Step {getCurrentStep(currentFile)}/{totalSteps} Completed
            </Typography>
          </>
        ) : null}
        {jobFailed ? (
          <Typography
            align="center"
            style={{ marginTop: 16, fontSize: 18, lineHeight: '21px', fontWeight: 600 }}
          >
            Something went wrong while parsing your file. If the problem persists, please email
            support@centerline.co.
          </Typography>
        ) : null}
        <Button
          color="primary"
          variant="contained"
          disabled={
            currentDrawingsFile?.progress?.stage === 'parsing' ||
            currentDrawingsFile?.percentComplete !== 100 ||
            isDownloading
          }
          onClick={beginRegionSelection}
          style={{ width: '100%', padding: 0, lineHeight: 1, marginTop: 16 }}
        >
          Begin Region Selection
        </Button>
      </div>
    );
  };

  const getDrawingsUploadDisabled = () => {
    return (
      !!currentDrawingsFile &&
      ![ExpandedJobStatusType.JobCreationFailed, ExpandedJobStatusType.Canceled].includes(
        currentDrawingsFile.status,
      )
    );
  };

  const publishAll = async () => {
    await publish(
      summary,
      currentProject,
      PublishType.Drawings,
      setIsPublishing,
      fetchSummary,
      dispatch,
      {
        publishGoodMatches: true,
        publishPoorMatches: true,
        publishExistingMatches: true,
      },
    );
    if (isDocumentLogPage) {
      dispatch(reloadDocuments());
    }
  };

  const drawingsButtons = [
    {
      name: 'Review Before Publishing',
      onClick: () =>
        history.push(`/main/projects/${currentProject!.id}/pub-center/conform-drawings`),
      color: '#0947B9',
    },
    {
      name: `Publish All`,
      onClick: publishAll,
      color: '#128750',
      pieDataIndex: undefined,
    },
  ];

  const content = (
    <CardContent className={classes.cardBody}>
      <Typography className={classes.text}>
        Centerline automatically builds a drawings log with individual entries for each drawing that
        are named and numbered according to the title block. To do this, please identify the
        location of the Drawing Number and the Drawing Title on each drawing.
      </Typography>
      <div style={{ display: 'flex', flexDirection: 'column', width: '85%' }}>
        <div className={classes.buttonAndForm} style={{ width: '100%', marginBottom: 16 }}>
          <Tooltip
            arrow
            placement="top"
            title="Multi-page files are preferred. You may only upload one file at a time. You may repeat this process as many times as necessary"
            style={{ marginRight: 8 }}
          >
            <Info />
          </Tooltip>
          <Button
            color="primary"
            variant="contained"
            disabled={getDrawingsUploadDisabled()}
            onClick={() => setDrawingsDialogOpen(true)}
            style={{ width: '96%', padding: 0, lineHeight: 1 }}
          >
            Upload Drawings Document
          </Button>
        </div>
        {isDownloading ? (
          <>
            <Typography align="center" style={{ fontSize: 14, marginBottom: 4 }}>
              Loading optimized drawings...
            </Typography>
            <LinearProgress
              variant="determinate"
              value={downloadProgress}
              style={{ width: '100%', height: 6, marginBottom: 16 }}
            />
          </>
        ) : null}
      </div>
      {getDrawingsProgressContent()}
      {!isPublishing ? (
        drawingsButtons.map((item) => {
          const disabled = getButtonIsDisabled(drawingsFiles, drawingsPieData, item.pieDataIndex);
          const isProcessing = drawingsFiles.some(
            (f) =>
              f.status === ExpandedJobStatusType.Pending ||
              f.status === ExpandedJobStatusType.Initiated ||
              f.status === ExpandedJobStatusType.CreatingJob,
          );
          return (
            <Button
              key={item.name}
              disabled={disabled}
              color="inherit"
              variant="contained"
              onClick={item.onClick}
              style={{
                width: '85%',
                marginBottom: 15,
                lineHeight: 1,
                backgroundColor: getButtonBackgroundColor(item.color, disabled),
                color: disabled ? '#A3A3A3' : 'white',
              }}
            >
              {!isProcessing ? item.name : 'Processing...'}
            </Button>
          );
        })
      ) : (
        <CircularLoader />
      )}
    </CardContent>
  );

  return (
    <>
      <Grid container item xs={12} lg={6}>
        <Grid container item xs={12}>
          <Paper className={classes.paper}>
            <Card style={{ height: '100%' }}>
              <CardContent className={classes.titleStyle}>
                <h2 style={{ textAlign: 'left', color: '#FFFFFF', margin: 0 }}>
                  {isDocumentLogPage ? 'Publish & Conform' : 'Drawings'}
                </h2>
                {isDocumentLogPage ? (
                  <IconButton onClick={() => setOpen((prev) => !prev)} style={{ padding: 0 }}>
                    {open ? <Remove htmlColor="#FFF" /> : <Add htmlColor="#FFF" />}
                  </IconButton>
                ) : null}
              </CardContent>
              {isLoading ? (
                <div
                  style={{
                    display: 'flex',
                    width: '100%',
                    height: '100%',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <CircularLoader style={{ marginBottom: 40 }} />
                </div>
              ) : (
                <>{isDocumentLogPage ? <Collapse in={open}>{content}</Collapse> : content}</>
              )}
            </Card>
          </Paper>
        </Grid>
      </Grid>
      <FileUploadDialog
        open={drawingsDialogOpen}
        handleClose={() => setDrawingsDialogOpen(false)}
        handleSubmit={submitDrawingsFile}
        buttonType="submit"
        title="Upload Drawings"
        addFile={addDrawingsFile}
        removeFile={removeDrawingsFile}
        canSubmit={inputDrawingsFile !== undefined}
        file={inputDrawingsFile}
        allowMultiple={false}
        uploadProgress={uploadProgress}
        allowedExtensions={['.pdf']}
        disableComments
        customMessage={
          <span style={{ width: '100%', fontWeight: 600, marginTop: 8 }}>
            Multi-page files are preferred.
            <br />
            You may only upload one file at a time.
          </span>
        }
        enableDocumentImport
      />
      {drawingsFileForParser && regionSelectorOpen && (
        <DrawingsDialog
          open={regionSelectorOpen}
          handleClose={() => {
            setRegionSelectorOpen(false);
            setInputDrawingsFile(undefined);
            setDrawingsFileForParser(undefined);
          }}
          onSubmit={(titleBlockCropConfig: CropConfig, sheetNameCropConfig: CropConfig) => {
            setRegionSelectorOpen(false);
            handleParsedDrawingSubmit(titleBlockCropConfig, sheetNameCropConfig).then(() =>
              console.log('UPLOADED'),
            );
          }}
          file={drawingsFileForParser}
        />
      )}
    </>
  );
}
